<template>
  <div
    v-if="order"
    class="add-new-item-admin"
  >
    <title-bar
      submitBtn
      :submitBtnTitle="$t('messages.orderUpdate')"
      :title="$t('messages.orderUpdate')"
      @submitPressed="submitPressed"
    />
    <order-form :order-obj="order" :action="action" @clearAction="clearAction" />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import OrderForm from '@/views/Admin/Orders/components/OrderForm.vue';

export default {
  components: {
    TitleBar,
    OrderForm
  },
  data() {
    return {
      action: null,
      order: null
    }
  },
  created() {
    this.loadOrder()
  },
  methods: {
    loadOrder() {
      const id = this.$route.params.id;
      this.$Orders.getResource({ id }, 'order_edit').then((response) => {
        this.order = response.data
        const fromDate = new Date(response.data.fromDate)
        const toDate = new Date(response.data.toDate)
        fromDate.setTime(fromDate.getTime() + (12*60*60*1000))
        toDate.setTime(toDate.getTime() + (12*60*60*1000))
        this.order.fromDate = new Date(fromDate.toISOString())
        this.order.toDate = new Date(toDate.toISOString())
        this.order.statusSelect = {
          label: this.$helper.getEnumTranslation('order_status', this.order.status, this.$i18n.locale),
          id: this.order.status
        }
        this.order.financialStatusSelect = {
          label: this.$helper.getEnumTranslation('order_payment_status', this.order.financialStatus, this.$i18n.locale),
          id: this.order.financialStatus
        }
        this.order.resortSelect = {
          label: response.data.resort.name,
          id: response.data.resort['@id'],
          numberId: response.data.resort.id
        }
        this.order.roomSelect = {
          label: response.data.room.name,
          id: response.data.room['@id'],
          numberId: response.data.room.id
        }

        this.order.transferSelect = {
          label: this.$helper.getEnumTranslation('resort_transfer_type', response.data.transfer, this.$i18n.locale),
          id: response.data.transfer
        }
        if('mealPlan' in response.data) {
          this.$MealPlans.getResourceByUrl({ url: response.data.mealPlan }, 'meal_plan_list').then((mealplan) => {
            this.order.mealPlanSelect = {
              label: this.$helper.getEnumTranslation('meal_plan_type', mealplan.data.type, this.$i18n.locale),
              id: mealplan.data['@id']
            }
          })
        }
      });
    },
    clearAction() {
      this.action = null;
      this.loadOrder();
    },
    submitPressed() {
      this.action = 'update'
    }
  }
};
</script>

<style lang="scss">
</style>
